// jQueryプラグイン
import 'jquery.easing';
/* -----------------------------------------------------
ページ内リンクのアニメーション
------------------------------------------------------- */
export function pageLinkAnimationFunc() {
  var scrTop, jsAnkerLogin, moveLogin, position, target, getHeadH;
  scrTop = $('#scrPageTop');
  scrTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1500, 'easeInOutQuint');
    return false;
  });
}

export function pageMoveAnkerFunc() {
  var headerHeight,position;
  $('a[href^="#"]:not(.notScroll)').each(function () {
    var pageUrl = location.href.split('#')[0];
    if (this.href.indexOf(pageUrl + '#') === 0) {
      var id = this.href.split('#')[1];
      $(this).on('click', function () {
        headerHeight = $('#header').outerHeight(true);
        position = $('#' + id).offset().top - headerHeight;
        $('body,html').animate({
          scrollTop: position
        }, 1000, 'easeInOutQuart');
        return false;
      });
    }
  });


  var url = $(location).attr('href');

  if (url.indexOf("?id=") != -1) {
    var id = url.split("?id=");
    var $target = $('#' + id[id.length - 1]);

    if ($target.length) {
      var headerHeight = $('#header').outerHeight();

      if ($('#subNav').length) {
        var subNavHeight = $('#subNav').innerHeight();
        var pos = $target.offset().top - headerHeight - subNavHeight;
        $("html, body").animate({
          scrollTop: pos
        }, 1000, 'easeInOutCubic');
      } else {
        var _pos = $target.offset().top - headerHeight;

        $("html, body").animate({
          scrollTop: _pos
        }, 1000, 'easeInOutCubic');
      }
    }
  }
}