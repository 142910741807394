export function headerFunc() {
  let headerPage = $('#header').length;
  const header = document.getElementById("header");
  const navBtn = $('#navBtn');
  const navMenu = $('#navMenu');
  let menuOpenFlg = 0;
  let scrollPosition;
  
  if (headerPage) {
    function indexHeaderScrFunc(){
      let scrTop = $(window).scrollTop();
      if (menuOpenFlg == 0) {
        if (scrTop < 20) {
          header.classList.remove('changeColor');
        } else {
          header.classList.add('changeColor');
        }
      }
    }
    indexHeaderScrFunc();
    $(window).on('scroll', function () {
      indexHeaderScrFunc();
    });
  }
}

export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}