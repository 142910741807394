//トップページ　キービジュアルスライダー
export function idxFunc() {

  const index = $("#indexTop").length;
  if(index){
    let mainvisualImg = $(".mainVisual .imgBox");
    let mainvisualBg = $(".mainVisual .mainVisualInner");
    let mainvisualText = $(".mainVisual .mainVisualInner .textBox");

    const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
    wait(0)
      .then(() => {
        mainvisualBg.addClass("isShow");
        return wait(200)
      }).then(() => {
        mainvisualImg.addClass("isShow");
        return wait(600)
      }).then(() => {
        mainvisualText.addClass("isShow");
      })


      const footerAccordionBtn = $('.questions .accordionBtn');
      let resizecheck = $('#responsibleCheck').width();
      let resizecheck2 = 0;
    
      $(window).on('load resize', function () {
        resizecheck = $('#responsibleCheck').width();
      });
        
      //アコーディオン
    
      footerAccordionBtn.next('dd').css('display', 'none');
      footerAccordionBtn.on('click', function () {
        $(this).next('dd').slideToggle(500);
          if ($(this).hasClass('isOpen')) {
              $(this).removeClass('isOpen');
          } else {
              $(this).addClass('isOpen');
          }
      }); 


  }
}




export function contactFunc() {
  const contactForm = $('#contactForm').length;
  if (contactForm) {
    let selectIndex = '';

    // 必須項目名
    const myName = $("[name='entry.985291183']");
    const myHurigana = $("[name='entry.1590612968']");
    const email = $("[name='entry.644215909']");
    // const emailCheck = $("[name='emailCheck']");
    const telNumber = $("[name='entry.921495511']");
    const content = $("[name='entry.2030543575']");
    const occupation = $("[name='entry.33908593']");
    const age = $("[name='age']");
    const contactType = $("[name='contactType']");
    /**************/
    const agreeCheck = $("[name='agreeCheck']");

    // 必須項目定義
    const onlineRequired = [myName, myHurigana, email, telNumber, content ,occupation];

    // 必須項目設定
    let requiredField = [];
    const requiredFieldSet = function () {
      requiredField = onlineRequired;
      for (let i = 0; i < requiredField.length; i++) {
        let requiredFieldType = requiredField[i].attr('type');
        if (requiredFieldType == 'radio' || requiredFieldType == 'checkbox') {
          requiredField[i].change(function () {
            fieldCheck(requiredField[i]);
          });
        } else {
          requiredField[i].blur(function () {
            fieldCheck(requiredField[i]);
          });
        }
      }
    }

    // リロード時実行
    requiredFieldSet();

    const agreeCheckTxt = '「プライバシーポリシーに同意する」にチェックをしてください';
    const emailRequiredTxt = '正しいメールアドレスを入力してください';
    const emailDuplication = '上記フォームで入力したメールアドレスと異なります';
    const telRequiredTxt = '13桁以内の数字を入力してください';
    const ageRequiredTxt = '半角英数字を入力してください';

    let errorTxt = '';
    const fieldCheck = function (checkField, all = false) {
      let checkFieldValue = checkField.val();
      let checkFieldType = checkField.attr('type');
      let checkFieldTag = checkField[0].tagName;
      let checkFieldName = checkField.attr('name');
      let checkFieldLabel = $(`[for= '${checkFieldName}']`);
      let checkFieldLabelTxt = checkFieldLabel.text().replace("※", "");
      if (checkFieldType == 'radio') {
        errorTxt = checkFieldLabelTxt + 'は選択必須です';
        if (!checkField.is(':checked')) {
          checkField.parents('.radioArea').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.radioArea').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkField == agreeCheck) {
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea').addClass('error').next('.errorTxt').text(agreeCheckTxt);
        } else {
          checkField.parents('.checkboxArea').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkField.hasClass('select')) {
        errorTxt = checkFieldLabelTxt + 'は選択必須です';
        if (checkFieldValue == '') {
          checkField.addClass('error').parent('.selectBtn').next('.errorTxt').text(errorTxt);
        } else {
          checkField.removeClass('error').parent('.selectBtn').next('.errorTxt').text('');
        }
      } else {
        errorTxt = checkFieldLabelTxt + 'は入力必須です';
        if (checkFieldValue == '') {
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } 
        else if (checkField == myName && checkFieldValue.length >= 51) {
          errorTxt = checkFieldLabelTxt + 'は入力必須です';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        }
        else if (checkField == myHurigana && checkFieldValue.length >= 51) {
          errorTxt = checkFieldLabelTxt + 'は入力必須です';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        }
        else if (checkFieldType == 'email' && !checkFieldValue.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
          checkField.addClass('error').next('.errorTxt').text(emailRequiredTxt);
        } 
        else if (checkField == email && checkFieldValue.length >= 51) {
          errorTxt = checkFieldLabelTxt + 'は入力必須です';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        }
        else if (checkFieldType == 'tel' && !checkFieldValue.match('^[0-9]')) {
          checkField.addClass('error').next('.errorTxt').text(telRequiredTxt);
        } 
        else if (checkField == telNumber && checkFieldValue.length > 13) {
          checkField.addClass('error').next('.errorTxt').text(telRequiredTxt);
        }
        else {
          checkField.removeClass('error').next('.errorTxt').text('');
        }
      }
    };

    content.blur(function () {
      if (content.val().length >= 501) {
        errorTxt = '500文字以内で入力してください';
        content.addClass('error').next('.errorTxt').text(errorTxt);
      } else {
        content.removeClass('error').next('.errorTxt').text('');
      }
    });

    let sendBtn = $('#sendBtn');
    // 各項目をサブミット処理でチェック（個人、法人フォーム）
    $('form').submit(function (e) {
      // 初期設定
      let set = 0;
      if (set == 0) {
        set = 1;
      } else {
        return false;
      }

      // 各項目をチェック（個人フォーム用）
      for (let i = 0; i < requiredField.length; i++) {
        fieldCheck(requiredField[i]);
      }

      // エラー箇所があればその場所までスクロール
      let offsetTxt, headerHeight;
      if ($('.errorTxt').text() != '') {
        offsetTxt = $('.error').offset().top;
        headerHeight = $("#header").outerHeight();
        $('html,body').animate({
          scrollTop: offsetTxt - headerHeight
        }, 500);
        return false;
      }

      // エラーがなければ二重送信禁止
      sendBtn.prop('disabled', 'true');
      setTimeout(function () {
        sendBtn.prop('disabled', '');
      }, 1000);
      return true;
    });

    // ラジオボタンチェック時入力欄有効可
    const radioBtn = document.querySelectorAll('.radioBtn');
    const radioField = document.querySelectorAll('.radioField');
    for (let i = 0; i < radioBtn.length; i++) {
      if (radioBtn[i].checked) {
        radioField[i].disabled = false;
      } else {
        radioField[i].disabled = true;
      }
    }
    $("input[type='radio']").on('click', function () {
      for (let i = 0; i < radioBtn.length; i++) {
        if (radioBtn[i].checked) {
          radioField[i].disabled = false;
        } else {
          radioField[i].disabled = true;
          radioField[i].value = '';
        }
      }
    });

    // チェックボックスチェック時入力欄有効可
    const checkBtn = document.querySelectorAll('.checkBtn');
    const checkField = document.querySelectorAll('.checkField');
    for (let i = 0; i < checkBtn.length; i++) {
      if (checkBtn[i].checked) {
        checkField[i].disabled = true;
        checkField[i].value = '';
      } else {
        checkField[i].disabled = false;
      }
    }
    $("input[type='checkbox']").on('click', function () {
      for (let i = 0; i < checkBtn.length; i++) {
        if (checkBtn[i].checked) {
          checkField[i].disabled = true;
          checkField[i].value = '';
        } else {
          checkField[i].disabled = false;
        }
      }
    });
  }
}
